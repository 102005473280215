import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "centurion" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "centurion-elcyklar---din-guide-till-framtidens-cykling"
    }}>{`Centurion Elcyklar - Din Guide till Framtidens Cykling`}</h1>
    <p>{`Välkommen till vår sida dedikerad till Centurions utbud av elcyklar. Här finner du en översikt över de olika serierna från Centurion, deras unika egenskaper och vad som gör varje serie speciell. Oavsett om du letar efter en elcykel för dagligt pendlande i stadsmiljö eller för äventyrliga turer på grusvägar, har Centurion något för dig.`}</p>
    <h2 {...{
      "id": "centurion-dark-image-elcykel"
    }}>{`Centurion Dark Image Elcykel`}</h2>
    <p><strong parentName="p">{`Centurion Dark Image`}</strong>{` är en toppmodern elcykel som seamlessly kombinerar sportig design med praktisk funktionalitet. Med sitt kraftfulla 250W Promovec bakmotor och robusta 460Wh batteri kan denna elcykel ta dig upp till 100 km på en enda laddning.`}</p>
    <h3 {...{
      "id": "huvudfunktioner"
    }}>{`Huvudfunktioner:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Effektiv bakmotor`}</strong>{`: 250W Promovec.`}</li>
      <li parentName="ul"><strong parentName="li">{`Imponerande batterikapacitet`}</strong>{`: 460Wh, upp till 100 km räckvidd.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sportig och robust design`}</strong>{`: Perfekt både för stadscykling och grusvägar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Avancerad teknik`}</strong>{`: 9-växlad Shimano Alivio M4000 och hydrauliska skivbromsar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Smarta funktioner`}</strong>{`: Promovec Connect+-skärm med Bluetooth.`}</li>
    </ul>
    <p>{`Den eleganta och iögonfallande designen i matt svart eller blank grön gör att du sticker ut från mängden medan du får en smidig och säker färd med högsta kontroll och uppkopplingsmöjligheter via din smartphone.`}</p>
    <h2 {...{
      "id": "köpguide-för-centurion-elcyklar"
    }}>{`Köpguide för Centurion Elcyklar`}</h2>
    <p>{`När du står inför valet av en Centurion elcykel kan det vara bra att tänka på dina specifika behov och användningsområden. Här är några tips som kan hjälpa dig att fatta ett välinformerat beslut:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Användningsområde`}</strong>{`: Fundera på var du kommer att använda elcykeln mest. Är det i staden eller på grusvägar och skogsstigar? `}<strong parentName="p">{`Centurion Dark Image`}</strong>{` är ett utmärkt val för både stadsmiljö och mer äventyrliga färder.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Räckvidd och batterikapacitet`}</strong>{`: Om du planerar att göra längre resor eller vill ha en elcykel som håller hela dagen, är batterikapaciteten viktig. `}<strong parentName="p">{`Centurion Dark Image`}</strong>{` erbjuder en imponerande räckvidd upp till 100 km.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Tekniska funktioner`}</strong>{`: För dem som vill ha den senaste tekniken, erbjuder `}<strong parentName="p">{`Centurion Dark Image`}</strong>{` en sofistikerad Promovec Connect+-skärm med Bluetooth, vilket ger dig full kontroll och anpassningsmöjligheter via din mobil.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Stil och design`}</strong>{`: Din elcykel ska inte bara vara funktionell, utan även spegla din personliga stil. Med sitt eleganta utseende i färger som matt svart och blank grön, är `}<strong parentName="p">{`Centurion Dark Image`}</strong>{` ett modeuttalande på två hjul.`}</p>
      </li>
    </ol>
    <p>{`Genom att ta hänsyn till dessa faktorer kan du välja den Centurion-elcykel som bäst passar dina behov och livsstil. Bläddra genom våra Centurion-serier för att hitta din perfekta följeslagare på vägen.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      